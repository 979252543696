/**
 * Cards
 */

import React from 'react';
import classnames from 'classnames';
import get from 'lodash/get';
import transparentPng from '@assets/images/transparent.png';
import { graphql } from 'gatsby';
import { ContentParser, Image, Link } from '@components';


const Cards = ({ cards }) => {
  if (!Array.isArray(cards)) {
    return null;
  }

  return (
    <div className="page__block--cards-list">
      {cards.map((card, index) => {
        const image = get(card, 'image.localFile.childImageSharp.fluid', null);
        const Element = card.cta_href ? Link : 'a';
        const ctaProps = {};

        if (card.cta_href) {
          ctaProps.href = card.cta_href;
        }

        return (
          <div className={classnames('card', 'card--action', {'has-image': !!image})} key={index}>
            <Element className="card--action__content" {...ctaProps}>
              {image && (
                <div className="card--action__image">
                  <Image 
                    fluid={image}
                    alt={card.image_alt} 
                    style={{
                      width: '100%',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                    }} 
                  />
                  <img alt="" src={transparentPng} aria-hidden="true" />
                </div>
              )}
              {card.title && <ContentParser className="card--action__title" content={card.title} />}
              {card.subtitle && <ContentParser className="card--action__subtitle" content={card.subtitle} />}
            </Element>
            {card.cta_button_href && card.cta_label && (
              <div className="card--action__button-wrapper">
                <Link className="button no-margin" href={card.cta_button_href}>
                  {card.cta_label}
                </Link>
              </div>
            )}
          </div>
        )
      })}
    </div>
  );
}

export default Cards;

export const pageBuilderCardsQuery = graphql`
  fragment PageBuilderCards on WordPressAcf_cards {
    ... on WordPressAcf_cards {
      cards {
        title
        subtitle
        image_alt
        cta_label
        cta_href
        cta_button_href
        image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 150, maxHeight: 150) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`